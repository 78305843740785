import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Box } from '@mui/material';

import { Skeleton } from 'components';

import { TOPIC_DISPLAY_LIMIT } from './PopoverTopicSelector';

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: theme.spacing(2),

		[theme.breakpoints.down('md')]: {
			padding: theme.spacing(4),
			width: '100%',
			display: 'grid',
			gridGap: theme.spacing(2),
			gridTemplateColumns: 'repeat(2, minmax(0, 1fr))'
		},
		[theme.breakpoints.down(350)]: {
			gridTemplateColumns: 'repeat(1, minmax(0, 1fr))'
		}
	},
	button: {
		borderRadius: '6px',
		width: 155,
		padding: 0,
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},
		'&': {
			transform: 'scale(1)'
		}
	}
}));

type Props = {
	allButtons: boolean;
};
export default function TopicSelectorSkeleton({ allButtons }: Props) {
	const classes = useStyles();
	const length = !allButtons ? 1 : TOPIC_DISPLAY_LIMIT;
	return (
		<Box className={classes.container}>
			{Array.from({ length }).map((_, index) => (
				<Skeleton height={44} className={classes.button} key={`TopicSelectorSk_${index}`} />
			))}
		</Box>
	);
}
