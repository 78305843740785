import { Popover, Box } from '@mui/material';
import React, { useMemo } from 'react';
import { Topics } from '../../../containers/Topics/Topics';
import { TopicsForSelectorQuery } from '../../../graphql/catalog/queries';
import useStyles from './TopicSelector.styles';
import classnames from 'classnames';
import { TopicItem } from './TopicItem';
import { useIntl } from 'react-intl';
import { SelectorTopic } from './TopicSelector';
import { ColoredIcon, formatters } from 'common';
import { Button } from 'components';

export const TOPIC_DISPLAY_LIMIT = 6;

type PopoverProps = {
	sortedTopics: TopicsForSelectorQuery['topicDocuments']['data'];
	setStateTopic: (topic: SelectorTopic | undefined) => void;
	displayTopicButtons: boolean;
	displayAllTopic: boolean;
	stateTopic: SelectorTopic | undefined;
	displayAbbreviation?: boolean;
};
const TopicsPopover = ({
	sortedTopics,
	displayTopicButtons,
	stateTopic,
	displayAllTopic,
	setStateTopic,
	displayAbbreviation
}: PopoverProps) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
	const classes = useStyles({ bold: displayTopicButtons, onlyCodes: displayAbbreviation });
	const { formatMessage, locale } = useIntl();

	const handlePopoverClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handlePovpoverClose = () => {
		setAnchorEl(null);
	};
	const onTopicItemClick = (id: string) => {
		const foundTopic = sortedTopics.find((t) => t.id === id);
		setStateTopic(foundTopic);
		setAnchorEl(null);
	};
	return (
		<>
			<Button
				className={classnames(classes.topicItem, classes.popoverButton)}
				onClick={handlePopoverClick}
				disabled={sortedTopics.length === 0}
				unstyled
			>
				{!displayTopicButtons && stateTopic ? (
					<>
						{stateTopic.specialityIconUrl && (
							<ColoredIcon
								url={stateTopic.specialityIconUrl}
								color={stateTopic.iconPrimaryColor}
								width={24}
								height={24}
							/>
						)}
						{formatters.formatTranslation(stateTopic.description, { locale })}
					</>
				) : (
					formatMessage({ id: 'catalog.topic-dropdown.all-topics' })
				)}
				<img
					src="https://bk-public-prod.storage.googleapis.com/public/static/arrow-down-type-2.svg"
					alt=""
					width={24}
					height={24}
				/>
			</Button>
			<Popover
				open={Boolean(anchorEl)}
				onClose={handlePovpoverClose}
				anchorEl={anchorEl}
				PaperProps={{
					className: classes.paperContainer
				}}
				BackdropProps={{
					invisible: false
				}}
			>
				<Topics
					onClose={handlePovpoverClose}
					topics={sortedTopics}
					displayAllTopic={displayAllTopic}
					onTopicClick={onTopicItemClick}
					containerClassName={classes.topicsContainer}
				/>
			</Popover>
		</>
	);
};

type TopicButtonsProps = {
	sortedTopics: Array<SelectorTopic>;
	selectedTopicState: SelectorTopic | undefined;
	handleTopicChange: (t: SelectorTopic) => void;
	displayAbbreviation?: boolean;
};
const TopicButtons = ({
	handleTopicChange,
	selectedTopicState,
	sortedTopics,
	displayAbbreviation
}: TopicButtonsProps) => {
	const { locale } = useIntl();

	const usedTopics = useMemo(() => {
		let toDisplay = sortedTopics.filter((t) => t.displayButton);
		if (toDisplay.length === 0) {
			toDisplay = sortedTopics.slice(0, TOPIC_DISPLAY_LIMIT);
		}
		if (selectedTopicState && !toDisplay.find((t) => t.code === selectedTopicState.code)) {
			const extraTopic = sortedTopics.find((top) => top.code === selectedTopicState.code);
			if (extraTopic) {
				toDisplay = [extraTopic, ...toDisplay];
			}
		}
		return toDisplay;
	}, [sortedTopics, selectedTopicState]);

	return (
		<>
			{usedTopics.map((t) => (
				<TopicItem
					key={`topicItem_${t.code}`}
					isActive={selectedTopicState?.code === t.code}
					onClick={() => {
						handleTopicChange(t);
					}}
					iconUrl={t.specialityIconUrl}
					iconColor={t.iconPrimaryColor}
				>
					{displayAbbreviation ? (
						formatters.formatTranslation(t.abbreviation, { locale })?.toUpperCase() ||
						t.code
					) : (
						<span>{formatters.formatTranslation(t.description, { locale }) || ''}</span>
					)}
				</TopicItem>
			))}
		</>
	);
};

type Props = {
	setStateTopic: (topic: SelectorTopic | undefined) => void;
	sortedTopics: TopicsForSelectorQuery['topicDocuments']['data'];
	selectedTopicState: SelectorTopic | undefined;
	handleTopicChange: (topic: SelectorTopic) => void;
	displayTopicButtons: boolean;
	displayAllTopic: boolean;
	displayAbbreviation?: boolean;
	popoverPosition: 'start' | 'end';
};
export default function PopoverTopicSelector({
	setStateTopic,
	selectedTopicState,
	sortedTopics,
	displayTopicButtons,
	displayAllTopic,
	handleTopicChange,
	displayAbbreviation,
	popoverPosition
}: Props) {
	const classes = useStyles({ bold: displayTopicButtons, onlyCodes: displayAbbreviation });

	if (popoverPosition === 'end') {
		return (
			<Box className={classes.endPositionContainer}>
				{displayTopicButtons && (
					<div className={classes.container}>
						<TopicButtons
							sortedTopics={sortedTopics}
							handleTopicChange={handleTopicChange}
							selectedTopicState={selectedTopicState}
							displayAbbreviation={displayAbbreviation}
						/>
					</div>
				)}
				<TopicsPopover
					stateTopic={selectedTopicState}
					displayTopicButtons={displayTopicButtons}
					setStateTopic={setStateTopic}
					displayAllTopic={displayAllTopic}
					sortedTopics={sortedTopics}
					displayAbbreviation={displayAbbreviation}
				/>
			</Box>
		);
	}

	return (
		<Box className={classes.container}>
			<TopicsPopover
				stateTopic={selectedTopicState}
				displayTopicButtons={displayTopicButtons}
				setStateTopic={setStateTopic}
				displayAllTopic={displayAllTopic}
				sortedTopics={sortedTopics}
				displayAbbreviation={displayAbbreviation}
			/>
			{displayTopicButtons && (
				<TopicButtons
					sortedTopics={sortedTopics}
					handleTopicChange={handleTopicChange}
					selectedTopicState={selectedTopicState}
					displayAbbreviation={displayAbbreviation}
				/>
			)}
		</Box>
	);
}
